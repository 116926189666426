body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a0f2c;
  color: #fff;
}

.App-header {
  background-color: #1a237e;
  padding: 20px;
  text-align: center;
}

nav {
  margin-bottom: 20px;
}

nav button {
  background-color: #3949ab;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

nav button:hover {
  background-color: #5c6bc0;
}

h1 {
  font-size: 2rem;
}

form {
  margin: 20px 0;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

input {
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

button {
  background-color: #3949ab;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #5c6bc0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #283593;
  margin: 10px 0;
  padding: 20px;
  border-radius: 5px;
}

strong {
  color: #ffeb3b;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3949ab;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Estilo para centrar la pantalla de login */
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px; /* Fijar el ancho del formulario */
  box-sizing: border-box; /* Incluir padding y borde en el ancho */
}

.login-form h1 {
  margin-bottom: 20px;
}

.login-form label {
  margin: 10px 0 5px;
  color: black;
  width: 100%; /* Asegurar que el label ocupe todo el ancho del contenedor */
  text-align: left; /* Alinear el texto a la izquierda */
}

.login-form input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Asegurar que el input ocupe todo el ancho del contenedor */
  box-sizing: border-box; /* Incluir padding y borde en el ancho */
}

.login-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Asegurar que el botón ocupe todo el ancho del contenedor */
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form .error {
  color: red;
  margin-top: 10px;
}
